

 .review {

    width: 100%;
 }




.reviews-container {
    display: flex;
   justify-items: center;
    justify-content: center;
   width: 100%;
   margin-top: 100px;
   margin-bottom: 100px;
   
}

.reviewer {
   display: flex;
   align-items: center;
   
}

.review__background {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    background-color: whitesmoke;
    padding: 30px;
    border-radius: 15px;
    width: 800px;
    height: 180px;
 
}

.review__background--in-view {
  animation: slide-in 2s ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}


.stars {
    margin-bottom: -18px;
}




.name {
   font-weight: 200;
   margin-top: 20px;
   font-size: 24px;
   color: rgb(51, 49, 49);
}


.city {
   font-size: 12px;
   color: rgb(51, 49, 49);
}


.Verified {
   font-size: 12px;
   color: rgb(51, 49, 49);
}

.comment {
    font-size: 22px;
    font-weight: 200;
    color: rgb(51, 49, 49);
}


@media only screen and (max-width: 891px) {
    .review__background {
   
    width: 600px;
    height: 180px;
   
}

.comment {
    font-size: 20px;
}
.name {
   
   font-size: 20px;
}



   
}



@media only screen and (max-width: 500px) {

.comment {
    font-size: 18px;
}
.name {
   
   font-size: 18px;
}

.review__background {
    width: 280px;
   
}

   
}
