/* Profile.css */
.profile {
  width: 100%; /* Adjust the maximum width as needed */
 padding-top: 10px;
 padding-bottom: 10px;
  height: 500px;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center vertically */
  justify-content: center;
  background-color: #17252A;
 margin: 0 auto;
  color: whitesmoke;
}


.director-p {
    color: grey;
    
}

.profile-photo {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #3AAFA9;
}

.director-name {
    
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: -15px;
}

.description {
  font-size: 1.1rem;
  margin-top: 10px;
  padding: 0 20px;
  max-width: 700px;
}

@media (max-width: 958px) {

    .profile {
        text-align: center;
        display: flex;
        flex-direction: column;
        
}

.description {

  max-width: 500px;
}

  /* Mobile styles */
 

  .director-name {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.9rem;
    padding: 0 10px;
  }
}

@media (max-width: 500px) {

  

.description {

  max-width: 300px;
}


}

