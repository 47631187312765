.work-slides-container {
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
  gap: 50px;
  
}

.work-slides-container img {
  max-width: 100%;
  height: auto;
   
}



.reviews-p {
    font-size: 26px;
    max-width: 250px;
}

.work-images {
    height: 400px;
    width: 400px; 
    border-radius: 10px;
 
}

.review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    height: 400px;
    width:400px;
    background-color: #3AAFA9;
    border-radius: 10px;
}

.testimonail {
    margin-left: 50px;
    font-size: 40px;
    color: grey;
}

.melb-across {
    font-size: 16px;
}

.rating-work {
    display: flex;
    flex-direction: row;

}

@media only screen and (max-width: 864px) {
.work-slides-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  
}

.testimonail {
    margin-left: 50px;
    font-size: 36px;
    color: grey;
}





}



@media only screen and (max-width: 416px) {
    .review-container {
        height: 300px;
        width: 300px;
    }

    .work-images {
        height: 300px;
        width: 300px; 
        
    
    }

    .testimonail {
    font-size: 26px;
    margin-top: 50px;
 
}


  .reviews-p {
    font-size: 20px;
    max-width: 250px;
}


}


