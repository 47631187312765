.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;  /* This ensures the container takes up the full height of the viewport */
}




@media (max-width: 540px) {
  .video-container {
    padding: 10px; /* Add padding or adjust styles for smaller screens */
    margin-bottom: -40px;
    margin-top: -10px;

   
  }

  video {
    width: 100%; /* Make the video take up 100% of the container's width */
  
  }
}



/* Apply additional styles for screens up to 360px */
@media (max-width: 360px) {
  .video-container {
    padding: 5px; /* Adjust padding or other styles for even smaller screens */
  }
}