

.main-blog-wrapper {
  background-color: whitesmoke;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: -200px;
}


.blog-image {
  width: 100%;
  height: 320px;
}

.blog-section-container {
  display: flex;
  display: row;
  justify-content: space-evenly;
  align-items: center;
  

}


.blog-container {
  max-width: 400px;
  margin-bottom: 40px;
    margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 800px;
 
}

.blog-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.blog-container p {
  font-size: 18px;
  line-height: 1.5;
}

.blog-p {
    padding: 20px;
}

.p-color {
    color: #6b6e70;

}

@media only screen and (max-width: 768px) {
  .main-blog-wrapper {
    margin-top: 40px;
  }



  .blog-section-container {
    flex-direction: column;
  }

  .blog-container {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 408px) {
  .blog-container {
  max-width: 340px;

 
}

}
