.footer-fade {
  /* ... */
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.footer-fade.fade-in {
  opacity: 1;
}


.footer-items-wrapper {
    display: flex;
    flex-direction: row;      
}


.footer-wrapper {
  position: relative;
  width: 100%;
  margin-top: 200px;
}

.footer-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  
}

.overlay-footer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.black-container-footer {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 80%;
  background-color: #3aafa9;;
  clip-path: polygon(0% 0%, 100% 80%, 100% 100%, 0% 100%);
  z-index: 1;
  width: 100%;
}



.footer-header {
    position: relative;
  font-size: 46px;
  text-align: center;
  margin-top: 1rem;
  color: #FEFFFF;
  z-index: 2;
  padding-top: 40px;

  
}

.color-footer {
  color: #17252A;
  margin-right: 5px;
}



.footer-logo-container {
    position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  position: relative;
  z-index: 2;
}

.footer-logo {
  width: 200px;
  height: 200px;
  margin-left: -30px;
  margin-bottom: -80px;
}




.footer-p {
  color: #17252A;
  margin-top: 1rem;
  font-size: 16px;
}

.contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
line-height: 10px;
margin-top: 95px;
  z-index: 2;
}

.contact-wrapper h3 {
  color: #17252A;
  margin-bottom: 1rem;
}

.contact {
  color: #FEFFFF;
}

.react-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100px;

}




@media (max-width: 810px) {
    .footer-items-wrapper {
    display: flex;
    flex-direction: column; 
   

}

.contact-wrapper {
  display: flex;
  flex-direction: column;
margin-top: 0px;
margin-left: 35px;

}

.footer-header {
  font-size: 40px;
}


}

@media (max-width: 410px) {
.footer-header {
  font-size: 32px;
  
}

.black-container-footer {
  
  clip-path: polygon(0% 0%, 100% 60%, 100% 100%, 0% 100%);

}
}

