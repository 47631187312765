


.jumping-button {
  width: 220px;
  height: 60px;
  border: none;
  border-radius: 10px;
  background-color: grey;
  color: whitesmoke;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
}

.icon-contact {
  margin-left: 5px;
}

.jumping-button:hover {
  background-color: black;
  
}

.lease-button {
  background-color: #2B7A78;

}

.lease-button:hover {
  background-color: black;

}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

