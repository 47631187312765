.q-head {
    font-weight: 400;
}

.faq-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 100px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  margin: 0;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.faq-item p {
  margin: 0;
  padding: 10px;
 
}

.faq-item h3:hover {
  background-color: #e0e0e0; /* Changed background color on hover */
}

@media (max-width: 600px) {
  .faq-item h3 {
    font-size: 16px;
  }

  .faq-item p {
    font-size: 14px;
  }
}
