.content-lic {
    margin-top: 100px;

}


.lic-heading {
    display: flex;
    font-size: 26px;
    text-align: center;
    margin: 0 auto;
    max-width: 1000px;
    margin-top: 20px;
    
}

.back-ground-lic {
    width: 100%;
   
}

.let-chat-join {
    height: 50px;
    width: 200px;
    border: none;
    background-color: black;
    color: whitesmoke;
    font-weight: 800;
    font-size: 22px;
    border-radius: 10px;

}

.chatbutton-join-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}



@media (max-width: 520px) {

    .lic-heading {
    
    font-size: 20px;
    max-width: 350px;
    
    
}
   

}