.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;

}

th {
  background-color: #3AAFA9;
}

.t-heading {
    color: white;
}
