.blog-container-pages {
    display: flex;
    flex-direction: row;
    color: #201e20;
    
}

.heading-overlay-blog {
    height: 100%;
}

.flex-blog-section {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
}

.blog-pages-heading-change {
    color: #6b6e70;
}

.blog-pages-heading {
    font-weight: 900;
}

.blog-pages-p {
    color: #6b6e70;
}

.color-change-steps {
    color:  rgb(192, 165, 8);
}

@media (max-width: 700px) {
    .blog-container-pages {
    display: flex;
    flex-direction: column;
   
}

.flex-blog-section {
    margin-top: 40px;
    margin-bottom: -100px;
}


}