.thankyou-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 100px;
}

.thanks-heading {
    font-size: 36px;
}

.thanks-p {
    font-size: 18px;
}

.check-email {
    line-height: 50px;
    font-weight: 800;
}