.tiktok-section {
  text-align: center;
  margin: 20px auto;
  max-width: 1100px;
}

.heading-tik {
  font-size: 36px; /* Adjust size as needed */
  margin-bottom: 20px; /* Space between the heading and the TikTok videos */
  color: #808080; /* Adjust color as needed */
}

.tiktok-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  gap: 10px; /* Adds space between items */
  justify-content: center; /* Center items horizontally */
}

.tiktok-embed {
  flex: 1 1 calc(33.33% - 10px); /* Adjusted space between items */
  margin-bottom: 10px; /* Space below each video */
  max-width: 605px;
  min-width: 325px;
}


.tik-sp {

    color: #de8c9d;
}

@media (max-width: 768px) {
  .tiktok-container {
    flex-direction: column; /* Stack videos in a column on smaller screens */
    align-items: center; /* Center items in column view */
  }

  .tiktok-embed {
    flex: 1 1 100%; /* Full width in column view */
    margin-bottom: 10px; /* Reduced space for mobile view as well */
  }

  .heading-tik {
  font-size: 24px; /* Adjust size as needed */
  margin-bottom: 20px; /* Space between the heading and the TikTok videos */
  color: #808080; /* Adjust color as needed */
}

}

@media (max-width: 480px) {
  .tiktok-embed {
    width: 100%;
  }
}


