/* ReviewSection.css */



.review-more {
  width: 440px; /* Set the desired width */
  height: 180px; /* Set the desired height */
}


.happy {
  text-align: center;
}



.review-boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    max-width: 900px;
}



.review-heading {
  font-family: 'Lobster', cursive;
  font-size: 44px;
}



.review-grid-container {

  display: grid;
  place-items: center;
}



.image-review-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display 3 columns */
  gap: 20px; /* Adjust the gap between images */
}

.stars-reviews {
    margin-top: -20px;
    margin-bottom: 60px;
}


.review-number {
    color: #3AAFA9;
}

.review-box-heading {
    
    color: rgb(135, 129, 129);
    

}

.review-box-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  height: 200px;
  width: 250px;
}


.review-number {
    font-size: 54px;
    font-weight: 800;
    margin-bottom: 0px;
}

.review-button {
  margin-bottom: 40px;
  border: none;
  background-color: #17252A;
  color: whitesmoke;
  padding: 10px;
  font-weight: 900;
}

.review-button:hover {
  background-color: #3AAFA9;
}

@media (max-width: 500px) {
  .image-review-more-container {
   
    white-space: nowrap;
  }

  .review-more {
    width: 100%; /* Set width to 100% for responsive layout */
    height: auto;
    display: inline-block;
  }
}


@media (max-width: 1032px) {
  /* Apply mobile styles */
  .image-review-container {
    grid-template-columns: 1fr; /* Display 1 column */
  }

  .review-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
   
    gap: 20px;
   
}


}


@media (max-width: 500px) {

.review-heading {

  font-size: 36px;
  margin-top: 100px;
}

.review-more {
  width: 260px; /* Set the desired width */
  height: 140px; /* Set the desired height */
}

.rev-end-con {
  margin-bottom: -100px;
}


}


