

.tick-list {
  color: rgb(216, 216, 216);
}


/* Style unordered lists with ticks */


.price-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  
}

.price-box {
  width: 20%; 
  padding: 20px;
  margin: 10px;
  text-align: center;
  background-color: #17252A;
  border-radius: 10px;
  
}

.box-color-change {
  background-color: #000101;
  

}

.book-button {
    width: 220px;
    height: 60px;
    border: none;
    border-radius: 10px;
    background-color: #3AAFA9;
    color: whitesmoke;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;

}

.book-button:hover {
    background-color: grey;
    cursor: pointer;
}



.book-des {
    color: grey;

}

.h2-book {
    color: whitesmoke;
}

.dollar {
    font-size: 44px;
    font-weight: 800;
    margin-top: 0px;
}

.book-des-h4 {
    color: grey;
    font-weight: 900;
    font-size: 16px;
}


/* Media query for mobile view */
@media (max-width: 768px) {
  .price-card {
    flex-direction: column;
  }

  .price-box {
    width: 80%;
    margin: 10px auto;
  }
}
