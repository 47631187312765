/* GiveGoogleReview.css */

/* Container styles */
.give-google-review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

/* Feedback question styles */
.feedback-question {
  margin-bottom: 20px;
}

.feedback-button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
}

/* Positive feedback styles */
.positive-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.google-review-link {
  margin-top: 10px;
  font-size: 18px;
}

/* Negative feedback styles */
.negative-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .feedback-button {
    font-size: 14px;
  }

  .google-review-link {
    font-size: 16px;
  }
}
