/* ReviewSection.css */
.review-img {
  max-width: 100%;
  height: 260p;
  border-radius: 10px;
}

.image-review-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(335px,));
  gap: 20px;
  justify-items: center;
  margin-bottom: 100px;
  margin-left: 10px;
  margin-right: 10px;
}


@media (max-width: 724px) {
    .image-review-container {
        margin-bottom: 150px;
  
}


}