
  .call-option-nav {
    background-color: #17252A;
    border: none;
    
  
}


.mar-call {
  margin-top: 10px;
}


.icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icons-container > * {
  margin-right: 20px;
}

.icons-container > *:hover {
  animation: bounce 0.8s ease-in-out;
}

.call-text {
  color: #FEFFFF;
}

.call-box {
  background-color: #3AAFA9;
  padding: 15px;
  border-radius: 10px;
}

@keyframes bounce {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1.5);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #17252A;
  color: whitesmoke;
  padding: 10px;
}

.nav__options {
    font-size: 16px;
    font-weight: 800;
}

.nav__options:hover {
  animation: bounce 0.8s ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1.5);
  }
}



.logo {
    height: 250px;
    margin-bottom: -50px;
    margin-top: -50px;
}

.logo:hover {
  animation: logoBounce 0.8s ease-in-out;
}

@keyframes logoBounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}



.nav {
  display: flex;
  margin-right: 50px;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav li {
  margin-left: 20px;
}

.nav a {
  color: white;
  text-decoration: none;
}

.menu-button {
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu-button span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: whitesmoke;
    background-color: #3AAFA9;
    width: 100%;

}

.ends-header {
  font-weight: 800;
  margin-top: 0px;
  color: #17252A;
}

@media screen and (max-width: 768px) {
  .nav {
    position: absolute;
    top: 0px;
    left: -100%;
    width: 100%;
    height: calc(80vh - 60px);
    padding-bottom: 30px;
 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #17252A;
    transition: all 0.3s ease-in-out;
    color: #FEFFFF;
   
  }

  .nav.open {
    left: 0;
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
  }

  .nav li {
    margin: 20px 0;
  }

  .menu-button {
    display: block;
    
  }
  

  .menu-button.open span:nth-of-type(1) {
    transform: rotate(45deg) translate(5px, 5px);
    
  }

  .menu-button.open span:nth-of-type(2) {
    opacity: 0;
  }

  .menu-button.open span:nth-of-type(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .icons-container {
  display: flex;
  flex-direction: column;
  
  
}

.icons-container > * {
  margin-bottom: 20px;
  
}




.call-box {
 
  padding: 10px;
  border-radius: 10px;
 
}

.social-none {
  display: none;
}
}


@media screen and (max-width: 450px) {

  .call-text {
  font-size: 14px;
}

.call-box {
  
  padding: 8px;

}

.header-logo {
  height: 180px;

}



}

@media screen and (min-width: 769px) {

  .call-option-nav {
    margin-right: 40px;
    font-size: 20px;
  
}


}


