.h2-about-container-service {
    background-color: #201e20;
    width: 100%;
    max-width: 400px;
}

.color-cleaners {
    color: #17252A;
}

.flex-clean {
    display: flex;
    flex-direction: column;
    
    margin: 0 auto;
}

.heading-service {
padding-top: 50px;

}

.overlay-service {
    height: 100%;
  
}

.margin-service {
    margin-bottom: 40px;
   
}

.service-list-section {
    margin-top: 200px;
}

@media only screen and (max-width: 914px) {

.laundry-display {
   display: none;
}

.no-contracts {
    margin-top: 40px;
    margin-bottom: -100px;
}

}


@media only screen and (max-width: 768px) {

.margin-service {
    margin-bottom: -80px;
    background-color: #3aafa9;
    
    
}
.safe-heading {
    color: #feffff;
}
.color-service {
    color: #17252a;
    padding-bottom: 40px;
    max-width: 320px;
  
}

  
  
 
}

@media (max-width: 957px) {
    .service-wrap {
    display: flex;
    flex-direction: column;
   
}




}


@media (max-width: 416px) {
.no-contracts {
  width: 280px;
  
}

.heading-contracts {
    font-size: 32px;
}






}



