

.end-of-lease-cleaning {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

@media (max-width: 768px) {
 
}


@media (max-width: 710px) {
  


  .end-of-lease-cleaning {
  max-width: 630px;


}
}

@media (max-width: 576px) {
 

  .end-of-lease-cleaning {
  max-width: 450px;


}
}

@media (max-width: 495px) {


  .end-of-lease-cleaning {
    max-width: 390px;

  


}
}


@media (max-width: 430px) {


  .end-of-lease-cleaning {
    max-width: 360px;

  


}
}


@media (max-width: 385px) {


  .end-of-lease-cleaning {
    max-width: 320px;

  


}
}





/* Add more media queries as needed for smaller screen sizes */
