.p-end {
  display: flex;
  text-align: center;
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;



}



.list-items {
  display: flex;
  text-align: end;

}


.service-end-wrap {
  margin-bottom: 50px;
}

.checklist-heading {
  margin-top: 100px;
  font-size: 44px;
    font-family: 'Lobster', cursive;
    color: #003953;
}


.checklist-heading {
  text-align: center;
  
}


.service-info-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display 3 columns */
  gap: 20px; /* Add some gap between items */
 
  padding-top: 50px;
  padding-bottom: 50px;
}



/* Ensure images fill their container and maintain aspect ratio */
.service-item img {
  width: 100%; /* Ensure image fills its container */
  height: auto; /* Maintain aspect ratio */

}

/* Style service item content */
.service-item {
  text-align: center; /* Center align text */
  padding: 20px; /* Add padding around content */
  border: 1px solid #ccc; /* Add border for separation */
  border-radius: 10px;
}


/* Adjust layout for mobile devices */


@media (max-width: 500px) {
 

  .head-window-top, .rec-head, .checklist-heading {
  font-size: 36px;
 
}
  
}
@media (max-width: 768px) {
  .service-info-section {
    grid-template-columns: repeat(1, 1fr); /* Display 1 column */
  }

  .head-window-top, .rec-head, .checklist-heading {
  font-size: 36px;
 
}
  
}

