
.window-review {
  background-color: #003953;
  color: white;
  text-align: center;
  padding: 20px;
  height: 300px;
  max-width: 100%;
  margin: 0;
}

.review-card {
  margin-bottom: 20px;
}

.reviewer-name {
  font-size: 32px;
  margin-bottom: 10px;
}

.review-text {
  font-size: 24px;
  margin-bottom: 10px;
}

.stars {
  font-size: 24px;
  margin-bottom: 20px;
  color: rgb(58, 175, 169);
}

.arrows {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 80px;
}

.arrow-btn {
  background-color: transparent;
  border: none;
  color: rgb(58, 175, 169);
  font-size: 32px;
  cursor: pointer;
}

.arrow-btn:hover {
  color: white;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .review-card {
    padding: 10px;
  }

  .reviewer-name {
    font-size: 24px;
  }

  .review-text {
    font-size: 18px;
  }

  .stars {
    font-size: 18px;
  }

  .arrow-btn {
    font-size: 24px;
  }

  .arrows {
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .window-review {
    padding: 15px;
  }

  .review-card {
    margin-bottom: 15px;
  }

  .reviewer-name {
    font-size: 20px;
  }

  .review-text {
    font-size: 16px;
  }

  .stars {
    font-size: 16px;
  }

  .arrow-btn {
    font-size: 20px;
  }

  .arrows {
    gap: 10px;
  }
}
