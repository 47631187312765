
.close-button {
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    background-color: #FEFFFF;
}




.heading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}



.heading-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 100%; /* add this */
  
  transform: translateX(100%);
  animation: slideIn 3s forwards;
  transition: transform 0.8s ease-in-out;
  display: flex; /* add this */
  flex-direction: column; /* add this */
  
}



.heading-container {
  width: 100%;
  height: 100%;
  
}

.heading-overlay {
  position: relative; /* add this */
  width: 400px;
  height: 600px;
 
}

.heading-overlay::before {
  content: ""; /* add this */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80%;
  background-color: #17252A ;
  opacity: .6;
  opacity: 0.8;
  z-index: -1;
  
}


.heading {
  font-size: 44px;
  font-weight: bold;
  font-weight: 700;
  margin-left: 20px;
  color: #feffff;
 

}


.circles-container {
  position: absolute;
  top: 20%;
  right: 5%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.circle {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #17252A;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(20deg);
}

.circle-textmid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  line-height: 30px; /* set to the same height as the circle */
  color: whitesmoke;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  
}




.image-grid-container {
  position: relative;
}


.slides-container {
  display: flex;
  height: 600px;
  width: 100%;
  
  opacity: 0;
  animation: fadeIn 4s forwards;
  z-index: -1;
   position: relative; /* add this */
  
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.slides-container.loaded {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.image-slides {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-slides .image-slides-wrapper {
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.image-slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.color__change {
  color: #6b6e70;
}

.jump-container {
  margin-left: 20px;
  padding-bottom: 20px;

}



@media (max-width: 770px) {



.circles-container {
  display: none;
}



.heading-overlay::before {
  content: ""; /* add this */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 76%;
  background-color: #17252A ;
  opacity: .6;
  opacity: 0.8;
  z-index: -1;
  
}


  
}

@media (max-width: 768px) {


.circle {
  height: 200px;
  width: 200px;
}



  
}

@media (max-width: 440px) {
  .heading-overlay {
  width: 350px;
}

.heading {
  font-size: 40px;
}

  
}

@media (max-width: 380px) {
  .heading-overlay {
  width: 320px;
}
 

.heading {
  font-size: 36px;
}

  
}

@media (min-width: 1600px) {
  .slides-container {
  height: 700px;
}



  
}

@media (min-width: 1700px) {
  .slides-container {
  height: 800px;
}


  
}










