.message-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  margin-top: 80px;
  
}

.top-heading-pro-pic {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.profile-com {
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  border-radius: 50%;
}

.message-heading {
  color: #2c3e50;
  font-size: 34px;
  margin: 0;
}

.message-paragraph {
  color: #34495e;
  font-size: 18px;
  margin-bottom: 16px;
}

@media (max-width: 500px) {
    .profile-com {
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  border-radius: 50%;
}

.message-heading {
  color: #2c3e50;
  font-size: 24px;

  margin: 0;
}
 


}
