.p-price {
    display: flex;
    color:  #797979;
    font-weight: 900;
    justify-content: center;
    margin-top: 40px;
    font-size: 32px;
    
    
}




@media (max-width: 520px) {

    .p-price {
    
    font-size: 18px;
    
    
}
   

}