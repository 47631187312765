.containter-map {
    display: flex;

    align-items: center;
    
    margin: 0 auto;
    max-width: 1200px;

}

.p-map-wrap {
    max-width: 50%;
    padding: 20px;
    
}

.map-wrap {
    max-width: 50%;
    padding: 20px;
}

.end-leas-map-grey {
    color: grey;
}


@media (max-width: 768px) {
    .containter-map {
    display: flex;
    flex-direction: column;

}

.p-map-wrap {
    max-width: 80%;
    padding: 20px;
    
}

.map-wrap {
    max-width: 80%;
    padding: 20px;
}



}