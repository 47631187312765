.custom-qoute {
  color: grey;
  font-weight: 900;
  
}


.home-button {
  position: absolute;
  top: 490px;
  left: 40px;
  background-color: rgb(29, 29, 29);
  padding: 20px;
  border-radius: 10px;
  
}

.close-button {
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    border: none;
    background-color: #FEFFFF;
}

  .palm-tree-image {
    display: flex;
    height: 400px;
    position: absolute;
    left: 18.9px;
    top: 175px; 
  }


.circleTwo-wrapper--in-view {
  animation: slide-in 1s ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}



.scrolling-div--in-view {
  animation: slide-right 1s ease-out forwards;
}

@keyframes slide-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}




.services-container {
  background-color: whitesmoke;
  color: #FEFFFF;
  padding: 2rem;
  padding-bottom: 5rem;
  
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  opacity: 0;
  transition: opacity 0.9s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.service {
color: #17252A;
padding: 2rem;
transition: transform 1s ease-in-out;

}

.service-title {
font-size: 1.5rem;
margin-bottom: 1rem;
color: #17252A;

}



.service:hover {
transform: rotateY(20deg);
transition: transform .5s ease-in-out;
background-color: #92c0be;
border-radius: 20px;
transition: all 1s ease;
cursor: pointer;
}

.container__scrolling {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
 height: 100%;
  position: relative; 
}

.scroll-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.house__background { 
  display: block;
  width: 100%;
  height: 800px;
  
  
}



.black__container {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #2B7A78;
  clip-path: polygon(0% 80%, 100% 0%, 100% 100%, 0% 100%);
  z-index: 1;
  width: 100%;
  height: 380px;
}

.count-heading {
    color: #FEFFFF;
}


.circle-wrapper {
  position: absolute;
  top: -90px;
  right: 0;
  height: 200px;
  background-color: #17252A;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  width: 200px;
  text-align: center; 
}

.circle-wrapper {
  z-index: 2;
}


.circleTwo-wrapper {
  position: absolute;
  top: 170px;
  right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  

}



.circleTwo-wrapper {
  z-index: 2;
}

.circle-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  line-height: 30px; /* set to the same height as the circle */
  color: whitesmoke;
  position: relative;
  font-weight: 700;
  font-size: 24px;
  transform: rotate(20deg);
  
}

.circle-two {
  color: #17252A;
  font-size: 36px;
  font-weight: 600;
}






.scrolling-div {
  
  padding-left: 40px;
  position: absolute;
  top: 50px;
  left: 0;
}

.scrolling-div {
  z-index: 1;
  /* add other styles as needed */
}

.upgrade__title {
  font-size: 36px;
  color: whitesmoke;
  
  background-color: #17252A;
  width: 250px;
  height: 100%;
  padding: 50px;

}

.blog-home-section {
  margin-bottom: -100px;
}


.lease-clean-align-centre {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}


.contract-section {
  margin-top: 150px;
  display: flex;
  margin-bottom: -200px;
 
}



@media (min-width: 1600px) {
 .container__scrolling {
  max-height: 900px;
 
}



.house__background { 
  height: 900px;
  
  
}
  
}

@media (max-width: 770px) { 
  .home-button {
  position: absolute;
  height: 188px;
  left: 40px;
  background-color: rgb(29, 29, 29);
  padding: 20px;
  border-radius: 10px;
}


}


@media (max-width: 768px) {

  

  

  .upgrade__title {
  font-size: 26px;
  color: whitesmoke;
  margin-bottom: 50px;
  margin-top: 40px;
  width: 160px;
  height: 55px;
  padding-top: 20px;
  padding-left: 20px;

}

.circle-two {
  color: #17252A;
  font-size: 26px;
  
}

.circleTwo-wrapper {
  top: 220px;
  right: 10px;
  
  
}

.circle-wrapper-two {
  height: 160px;
  width: 160px;
}


.container__scrolling {
  margin-bottom: -100px;
}



.house__background {
  height: 700px;
}

  
}


@media only screen and (max-width: 784px) {


.home-button {
  top: 490px;
}

.services-grid {
  grid-template-columns: repeat(2, 1fr);
  
}





}

@media only screen and (max-width: 600px) {
 

 

.services-grid {
  grid-template-columns: repeat(1, 1fr);
  
}

.black__container {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0% 100%);
  z-index: 1;
  width: 100%;
  height: 340px;
}

}


@media only screen and (max-width: 753px) {

  .home-next-container {
    margin-bottom: -45px;
  }




}

@media only screen and (max-width: 550px) {

  .container__scrolling {
  max-height: 450px;
}

.house__background {
  height: 450px;
}


}


@media only screen and (max-width: 450px) {

 




}









@media only screen and (max-width: 405px) {


  
  .close-button {
    margin-top: 10px;
    margin-left: 20px;
    border-radius: 10px;
    border: none;
    background-color: whitesmoke;
    

}
}

@media only screen and (max-width: 450px) {


.home-button {
  top: 410px;
  
}

  .upgrade__title {
  display: none;
}
  

  .container__scrolling {
  max-height: 400px;
}

.house__background {
  height: 400px;
}
}



.overlay-clean {
  margin-top: 20px;
}


.overlay-clean {
  margin-top: 20px;
}


.clean-slide-show {
  position: absolute;
  display: flex;
  z-index: -1;
  height: 230px; /* adjust the height as needed */
overflow: hidden;
  max-width: 100%;
}



.clean-image {
    border: 1px  solid #201e20;
}

.black-right {
    display: flex;
    position: absolute;
    right: 0;
    top: 172px;
    background-color: #17252a;
    height: 50px;
    width: 250px;
    align-items: center;
    justify-content: center;
}

.bond {
    color: rgb(206, 190, 99);

}


.end-lease-heading {
    color: whitesmoke;
    background-color: #3AAFA9;
    width: 100%;
    height: 50px;
    margin-top: 0px;
    padding-top: 20px;
    font-size: 24px;
    padding-bottom: 20px;

}





.end-p {
    display: flex;
    align-items: center;
    color: whitesmoke;
    font-weight: 700;
    font-size: 18px;

}

.end-of-lease {
    background-color: #17252a;
    width: 100%;
    height: 350px;
    margin-top: 110px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
   
}


.circle-clean {
  background-color: #3AAFA9;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 40px;
  top:30%;
 
}


.circle-clean-left {
    background-color: #3AAFA9;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 40px;
  top:30%;

}

.circle-text-clean {
  color: whitesmoke;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  transform: rotate(25deg);
}

 .review-clean {
        max-width: 580px;
        margin: 0 auto;
    }


.gray-circle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.circle-h6 {
  color: white;
  text-align: center;
  font-size: 16px;
  transform: rotate(10deg);
}

.circle-container-cleaning {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}


.agents-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  margin: 0 auto;

}




@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.black-right {
  animation: slideInRight 2s ease-out;
}

.agents-container {
  margin-bottom: 40px;
}


@media only screen and (max-width: 760px) {
    

.end-of-lease {
    margin-bottom: 100px;
}

.clean-p-service {
    color: whitesmoke;
    
}

.gray-circle {
  width: 150px;
  height: 150px;


}

.circle-h6 {
  
  font-size: 12px;

}
 

}





@media only screen and (max-width: 670px) {
    .review-clean, .safe-wrapper {
        max-width: 360px;
        
    }




 

}



@media only screen and (max-width: 956px) {

.review-clean {
        margin-bottom: -100px;
       
    }
.overlay-clean {
    height: 500px;
}

.end-p {
    font-size: 16px;
}
 .end-lease-heading {
    font-size: 20px;
    
}

.circle-clean {
  top:80%;
 
}


.circle-clean-left {
  top:80%;

}

}


@media only screen and (max-width: 550px) {
.overlay-clean {
    height: 500px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-left: 0px;

}



.circle-clean {

  width: 150px;
  height: 150px;
}


.circle-clean-left {
   
  width: 150px;
  height: 150px;
}



 

}


@media only screen and (max-width: 441px) {
.circle-clean {
  right: 20px;
}


.circle-clean-left {
   left: 20px;
}



}

@media only screen and (max-width: 396px) {
.circle-clean {
  right: 10px;
}


.circle-clean-left {
   left: 10px;
}

.end-of-lease {
    max-width: 350px;
    margin: 0 auto;
    margin-top: 100px;

}






}



 
