.end-lease-span {
  font-size: 12px;
}


.ends-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(235, 140, 140);
  font-weight: 800;
}

.countdown-timer {
  color: white;
}


.build-button {
    margin-bottom: 30px;
    background-color: #3AAFA9;
    border: none;
    border-radius: 20px;
    color: whitesmoke;
    font-size: 22px;
    font-weight: 800;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
}

.build-button:hover {
  background-color:  grey;
  color: whitesmoke;
}



.form-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    background-color: black;
    align-items: center;
    padding-bottom: 40px;

}



.popform__heading {
    color: whitesmoke;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
}

.popform__offer {
  color: whitesmoke;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 40px;
}

.result-msg {
    color: #3AAFA9;
    margin-top: -15px;
}

@media only screen and (max-width: 405px) {
  .popform__heading {
    color: whitesmoke;
    text-align: center;
    font-size: 24px;
    font-weight: 700;

}


.form-container {
   
    width: 340px;
   
}




}
