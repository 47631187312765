.responsive-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 100px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.head-window {
  font-size: 32px;
  font-family: 'Lobster', cursive;
  color: #003953;
}

.info-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.info-item {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
}

.info-item h3 {
  font-size: 24px;
  margin-top: 10px;
}

.info-item p {
  font-size: 16px;
  margin-top: 10px;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .info-item {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .head-window {
    font-size: 28px;  /* Slightly smaller for mobile */
  }

  .info-item h3 {
    font-size: 20px;
  }

  .info-item p {
    font-size: 14px;
  }

  .responsive-info {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (max-width: 480px) {
  .info-item {
    padding: 10px;
  }

  .head-window {
    font-size: 24px;
  }

  .info-item h3 {
    font-size: 18px;
  }

  .info-item p {
    font-size: 14px;
  }

  .responsive-info {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

